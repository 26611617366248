import React, { useState } from 'react';
import { Option, SupplierDetailsFormData } from '../models/supplier-models';
import * as yup from 'yup';
import { SUPPLIER_DETAILS_FORM_DATA_DEFAULT } from '../constants/supplier-default';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import ReCAPTCHA from 'react-google-recaptcha';
import { createSupplier, fetchTimsTaxCodes } from '../api';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

const stateOptions: Option[] = [{
  label: 'Queensland',
  value: 0
}];

const countryOptions: Option[] = [{
  label: 'Australia',
  value: 0
}];

const defaultCurrencyOptions: Option[] = [{
  label: 'Australia',
  value: 0
}];

const schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  abn: yup.string()
    .nullable()
    .notRequired()
    .test('is-empty-or-valid-abn', 'Invalid ABN format. ABN must be in the format XX XXX XXX XXX.', value => {
      if (!value) return true;
      return yup.string().matches(/^\d{2} \d{3} \d{3} \d{3}$/).isValidSync(value);
    }),
  contactName: yup.string().required('Contact Name is required.'),
  phone: yup.string(),
  email: yup.string().email('Invalid email format').required('Email is required.'),
  supplierSalutation: yup.string(),

  website: yup.string()
    .nullable()
    .notRequired()
    .url('Invalid URL format. Example: https://www.example.com')
    .test('is-empty-or-valid-url', 'Invalid URL format. Example: https://www.example.com', value => {
      if (!value) return true;
      return yup.string().url().isValidSync(value);
    }),

  address1: yup.string().required('Address 1 is required.'),
  address2: yup.string(),
  suburbOrCity: yup.string().required('Suburb/City is required.'),
  postCode: yup.string().required('Postcode is required.'),
  selectedState: yup.object().shape({
    value: yup.number().required('State is required.'),
    label: yup.string().required('State is required.'),
  }).required('State is required.'),
  selectedCountry: yup.object().shape({
    value: yup.number().required('Country is required.'),
    label: yup.string().required('Country is required.'),
  }).required('Country is required.'),

  selectedTaxCode: yup.object().shape({
    value: yup.number().nullable(),
    label: yup.string(),
  }),
  selectedDefaultCurrency: yup.object().shape({
    value: yup.number().nullable(),
    label: yup.string(),
  }),
  bsb: yup.string()
    .nullable()
    .notRequired()
    .test('is-empty-or-valid-bsb', 'BSB must be in ###-### format', value => {
      if (!value) return true;
      return yup.string().matches(/^\d{3}-\d{3}$/).isValidSync(value);
    }),

  account: yup.string(),
  accountName: yup.string(),
  remittanceEmail: yup.string()
    .nullable()
    .notRequired()
    .email('Invalid email format')
    .test('is-empty-or-valid-email', 'Invalid email format', value => {
      if (!value) return true;
      return yup.string().email().isValidSync(value);
    }),
  acceptTerms: yup.boolean().oneOf([true], 'You must accept the terms and conditions.').required()
});

interface IProps {
  show: boolean;
  onClose: () => void;
}

const loadingPanel = (
  <div className='k-loading-mask'>
    <span className='k-loading-text'>Loading</span>
    <div className='k-loading-image'></div>
    <div className='k-loading-color'></div>
  </div>
);

const CreateSupplierPage: React.FC<IProps> = ({ show, onClose }) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [taxCodeOptions, setTaxCodeOptions] = useState<Option[]>([]);

  const history = useHistory()

  const { register, handleSubmit, control, formState: { errors }, reset, getValues } = useForm({
    defaultValues: SUPPLIER_DETAILS_FORM_DATA_DEFAULT,
    resolver: yupResolver(schema) as any
  });

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const onSubmit = async (data: SupplierDetailsFormData) => {
    setSaving(true);
    setTimeout(() => {
        createSupplier(data, captchaToken)
        .then(_ => {
          history.push('/thank-you')
        })
        .catch(({ response: { data: { message } } }) => {
          setError(message)
        }).finally(() => {
          setSaving(false)
        });
    }, 1500)
  };

  const onReCAPTCHAChange = async (captchaToken: string | null) => {
    if (captchaToken) {
      setCaptchaToken(captchaToken);
    }
  };

  const handleBSBInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9-]*$/.test(value)) {
      e.target.value = value;
    } else {
      e.target.value = value.slice(0, -1);
    }
  };

  const handleAccountNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.toUpperCase();
  };

  const handleAccountInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      e.target.value = value;
    } else {
      e.target.value = value.slice(0, -1);
    }
  };

  useEffectOnce(() => {
    const fetchTaxCodes = async () => {
      const response = await fetchTimsTaxCodes();
      const taxCodes = response.data.map((taxCode: any) => ({
        label: taxCode.code,
        value: taxCode.id,
      }));
      setTaxCodeOptions(taxCodes);
    };

    fetchTaxCodes();
  });

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')` }}
        >
          <div className='d-flex flex-column flex-column-fluid text-center mt-10'>
            <a href='/'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/tall-ingots-logo.png')}
                className='h-50px mb-5'
              />
            </a>
            <h1 className='fw-bolder fs-3x text-gray-700 mb-10'>Supplier Form</h1>

          </div>

          <div className="card card-custom  px-20 pb-20">
            <div className='card-body'>
              <form onSubmit={handleSubmit(onSubmit)}>
                {loading && loadingPanel}
                {error && (
                  <div className='mb-lg-15 alert alert-warning'>
                    <div className='alert-text font-weight-bold'>{error}</div>
                  </div>
                )}

                <div>
                  <h6>Base Details</h6>
                  <div className="separator my-3 border-2 border-secondary"></div>
                  <div className="card card-custom">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Name</label>
                          <input
                            type="text"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            {...register('name')}
                            placeholder="Enter name"
                          />
                          {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">ABN</label>
                          <input
                            type="text"
                            className={`form-control ${errors.abn ? 'is-invalid' : ''}`}
                            {...register('abn')}
                            placeholder="Enter ABN"
                          />
                          {errors.abn && <div className="invalid-feedback">{errors.abn.message}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h6>Contact</h6>
                  <div className="separator my-3 border-2 border-secondary"></div>
                  <div className="card card-custom">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Contact Name</label>
                          <input
                            type="text"
                            className={`form-control ${errors.contactName ? 'is-invalid' : ''}`}
                            {...register('contactName')}
                            placeholder="Enter contact name"
                          />
                          {errors.contactName && <div className="invalid-feedback">{errors.contactName.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Phone</label>
                          <input
                            type="text"
                            className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                            {...register('phone')}
                            placeholder="Enter phone"
                          />
                          {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Email</label>
                          <input
                            type="text"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            {...register('email')}
                            placeholder="Enter email"
                          />
                          {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Salutation</label>
                          <input
                            type="text"
                            className={`form-control ${errors.supplierSalutation ? 'is-invalid' : ''}`}
                            {...register('supplierSalutation')}
                            placeholder="Enter salutation"
                          />
                          {errors.supplierSalutation && <div className="invalid-feedback">{errors.supplierSalutation.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Website</label>
                          <input
                            type="text"
                            className={`form-control ${errors.website ? 'is-invalid' : ''}`}
                            {...register('website')}
                            placeholder="Enter website"
                          />
                          {errors.website && <div className="invalid-feedback">{errors.website.message}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h6>Address</h6>
                  <div className="separator my-3 border-2 border-secondary"></div>
                  <div className="card card-custom">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Address 1</label>
                          <input
                            type="text"
                            className={`form-control ${errors.address1 ? 'is-invalid' : ''}`}
                            {...register('address1')}
                            placeholder="Enter address 1"
                          />
                          {errors.address1 && <div className="invalid-feedback">{errors.address1.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Address 2</label>
                          <input
                            type="text"
                            className={`form-control ${errors.address2 ? 'is-invalid' : ''}`}
                            {...register('address2')}
                            placeholder="Enter address 2"
                          />
                          {errors.address2 && <div className="invalid-feedback">{errors.address2.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Suburb/City</label>
                          <input
                            type="text"
                            className={`form-control ${errors.suburbOrCity ? 'is-invalid' : ''}`}
                            {...register('suburbOrCity')}
                            placeholder="Enter suburb/city"
                          />
                          {errors.suburbOrCity && <div className="invalid-feedback">{errors.suburbOrCity.message}</div>}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Postcode</label>
                          <input
                            type="text"
                            className={`form-control ${errors.postCode ? 'is-invalid' : ''}`}
                            {...register('postCode')}
                            placeholder="Enter postcode"
                          />
                          {errors.postCode && <div className="invalid-feedback">{errors.postCode.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">State</label>
                          <div className={`${errors.selectedState ? 'custom-select-error' : ''}`}>
                            <Controller
                              name="selectedState"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={stateOptions}
                                  value={value}
                                  onChange={(e: any) => onChange(e)}
                                  className='border-danger controllerSelect'
                                  placeholder="Select state"
                                />
                              )}
                            />
                          </div>
                          {errors.selectedState && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.selectedState?.label?.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Country</label>
                          <div className={`${errors.selectedCountry ? 'custom-select-error' : ''}`}>
                            <Controller
                              name="selectedCountry"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={countryOptions}
                                  value={value}
                                  onChange={(e: any) => onChange(e)}
                                  className='border-danger controllerSelect'
                                  placeholder="Select country"
                                />
                              )}
                            />
                          </div>
                          {errors.selectedCountry && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.selectedCountry?.label?.message}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h6>Payment Details</h6>
                  <div className="separator my-3 border-2 border-secondary"></div>
                  <div className="card card-custom">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Tax Code</label>
                          <div className={`${errors.selectedTaxCode ? 'custom-select-error' : ''}`}>
                            <Controller
                              name="selectedTaxCode"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={taxCodeOptions}
                                  value={value}
                                  onChange={(e: any) => onChange(e)}
                                  className='border-danger controllerSelect'
                                  placeholder="Select tax code"
                                />
                              )}
                            />
                          </div>
                          {errors.selectedTaxCode && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.selectedTaxCode?.label?.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Default Currency</label>
                          <div className={`${errors.selectedDefaultCurrency ? 'custom-select-error' : ''}`}>
                            <Controller
                              name="selectedDefaultCurrency"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={defaultCurrencyOptions}
                                  value={value}
                                  onChange={(e: any) => onChange(e)}
                                  className='border-danger controllerSelect'
                                  placeholder="Select currency"
                                />
                              )}
                            />
                          </div>
                          {errors.selectedDefaultCurrency && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.selectedDefaultCurrency?.label?.message}</div>}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">BSB</label>
                          <input
                            type="text"
                            className={`form-control ${errors.bsb ? 'is-invalid' : ''}`}
                            {...register('bsb')}
                            placeholder="Enter BSB"
                            onInput={handleBSBInput}
                          />
                          {errors.bsb && <div className="invalid-feedback">{errors.bsb.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Account</label>
                          <input
                            type="text"
                            className={`form-control ${errors.account ? 'is-invalid' : ''}`}
                            {...register('account')}
                            placeholder="Enter account"
                            onInput={handleAccountInput}
                          />
                          {errors.account && <div className="invalid-feedback">{errors.account.message}</div>}
                        </div>
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Account Name</label>
                          <input
                            type="text"
                            className={`form-control ${errors.accountName ? 'is-invalid' : ''}`}
                            {...register('accountName')}
                            placeholder="Enter account name"
                            onInput={handleAccountNameInput}
                          />
                          {errors.accountName && <div className="invalid-feedback">{errors.accountName.message}</div>}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label text-muted fs-8">Remittance Email</label>
                          <input
                            type="text"
                            className={`form-control ${errors.remittanceEmail ? 'is-invalid' : ''}`}
                            {...register('remittanceEmail')}
                            placeholder="Enter remittance email"
                          />
                          {errors.remittanceEmail && <div className="invalid-feedback">{errors.remittanceEmail.message}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-center">
                  <ReCAPTCHA
                    className="mb-10"
                    ref={recaptchaRef}
                    sitekey="6LcODrgpAAAAADg3cCPtrUHNltj3NjDTMl74sJXb"
                    onChange={onReCAPTCHAChange}
                  />

                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className={`form-check-input ${errors.acceptTerms ? 'is-invalid' : ''}`}
                      {...register('acceptTerms')}
                      id="acceptTerms"
                    />
                    <label className="form-check-label" htmlFor="acceptTerms" style={{ color: 'black' }} >
                      I accept the <a style={{ color: '#0000EE' }} href="/tallingots-terms-and-conditions.pdf" target="_blank" rel="noopener noreferrer">terms and conditions</a>
                    </label>
                    {errors.acceptTerms && <div className="invalid-feedback" style={{ position: 'relative', right: '32px' }} >{errors.acceptTerms.message}</div>}
                  </div>

                  <button className="btn btn-primary w-50" type="submit">
                    {!saving && <span className='indicator-label'> CREATE NEW SUPPLIER </span>}
                    {saving && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Creating new supplier...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSupplierPage;
